//rafce --> React arrow function export component
import React from 'react'
import ImageDragDrop from '../ImageDragDrop/ImageDragDrop'
import './WindowComponent.css';

class EvidenceEditWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: 'Label',
            description: 'Description',
            retrieved: false,
        };
    }

    titleOnChange(e) {
        this.setState({ label: e.target.value });
    }

    descriptionOnChange(e) {
        this.setState({ description: e.target.value });
    }

    render() {
        !this.state.retrieved &&
            (this.setState({
                label: this.props.valueLabel,
                description: this.props.valueDescription,
                retrieved: true,
            }));
        return (
            <div
                className={`EvidenceEditWindow__Back ${this.props.id}`}
                id={this.props.id}>
                <div className="EvidenceEditWindow__Container">
                    {this.props.type === "upload" && (
                        <div className="Edit-div-dragdrop">
                            <div className="Edit-div-left">
                                <p><strong>Screenshot</strong></p>
                            </div>
                            <div className="Edit-div-right dragdrop">
                                <ImageDragDrop/>
                            </div>
                        </div>
                    )}
                    {this.props.type === "image" && (
                        <div className="Edit-div screenshot-wrap">
                            <div className="Edit-div-left">
                                <strong>Image </strong>
                            </div>
                            <div className="Edit-div-right">
                                <img className="Edit-div-screenshot"
                                    src={'static/uploads/' + this.props.id + '.png'}
                                    alt="label"></img>
                            </div>
                        </div>
                    )}
                    <div className="Edit-div">
                        <div className="Edit-div-left">
                            <strong>Label </strong>
                        </div>
                        <div className="Edit-div-right">
                            <input
                                className="Edit-input"
                                type="text"
                                id="edit_ss_label"
                                value={this.state.label}
                                onChange={(e) => this.titleOnChange(e)}
                            ></input>
                        </div>
                    </div>
                    <div className="Edit-div-desc">
                        <div className="Edit-div-left">
                            <strong>Description </strong>
                        </div>
                        <div className="Edit-div-right">
                            <textarea
                                id="edit_ss_description"
                                className="Edit-textarea"
                                rows="4"
                                maxLength="120"
                                value={this.state.description}
                                onChange={(e) => this.descriptionOnChange(e)}
                            ></textarea>
                        </div>
                    </div>
                    <div className="Edit-div">
                        <div className="Edit-div-left">
                        </div>
                        <div className="Edit-div-right">
                            {this.props.type === "capture" ?
                                (<button
                                    id="save-capture-button"
                                    onClick={() => { this.props.onSave(this.state.label, this.state.description); }}
                                    className="EvidenceEditWindow__Button"
                                >Save</button>) :
                                (<button id="save-capture-button" onClick={() => { this.props.onSave(this.state.label, this.state.description); }}
                                    className="EvidenceEditWindow__Button">Save</button>)}
                            <button onClick={this.props.onCancel} className="EvidenceEditWindow__Button">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EvidenceEditWindow;

