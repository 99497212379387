const query = new URLSearchParams(window.location.search);
export const studentID = parseInt(query.get("studentID"));

export const constant = {
    data : {
        generalInstruction: {
          text1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Fermentum et sollicitudin ac orci phasellus. Sed viverra tellus in hac habitasse platea dictumst vestibulum rhoncus. Purus non enim praesent elementum facilisis leo. Nulla pharetra diam sit amet nisl suscipit. Lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci. Donec ac odio tempor orci dapibus ultrices. Morbi tristique senectus et netus et malesuada fames ac. At risus viverra adipiscing at in tellus. Consectetur purus ut faucibus pulvinar elementum integer enim neque volutpat. Euismod quis viverra nibh cras pulvinar mattis nunc sed blandit. Ultrices eros in cursus turpis massa tincidunt dui ut. Vitae auctor eu augue ut lectus arcu bibendum at. Vulputate dignissim suspendisse in est ante in. Sem nulla pharetra diam sit amet nisl suscipit adipiscing bibendum. Maecenas ultricies mi eget mauris pharetra et ultrices neque ornare. Id donec ultrices tincidunt arcu non sodales. Malesuada bibendum arcu vitae elementum. Tortor aliquam nulla facilisi cras fermentum odio.",
          text2: "Accumsan in nisl nisi scelerisque eu ultrices vitae auctor eu.A erat nam at lectus.Pulvinar mattis nunc sed blandit libero volutpat sed cras.Mauris commodo quis imperdiet massa tincidunt nunc pulvinar.Feugiat in fermentum posuere urna nec tincidunt praesent semper.Imperdiet sed euismod nisi porta lorem mollis.Libero enim sed faucibus turpis in eu mi bibendum.Etiam tempor orci eu lobortis elementum nibh.Tellus cras adipiscing enim eu.Erat imperdiet sed euismod nisi porta lorem mollis aliquam.Nulla malesuada pellentesque elit eget gravida.Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam.Praesent tristique magna sit amet.Aliquet eget sit amet tellus cras adipiscing enim eu.Ut sem nulla pharetra diam sit amet nisl suscipit adipiscing.Amet tellus cras adipiscing enim eu turpis egestas pretium aenean.",
        },
        sceneNotes: {
          text: "Here is the general scene instruction for student to read. Maximum 2 lines of text.",          
        },
        matterport: {
          floorplan_url: "/static/floorplan.jpg",
          scene_url: "https://my.matterport.com/show/?m=xsxLfjx5LUz&qs=1&play=1&log=0&search=0",
        },
        server: {
          dev_url: process.env.REACT_APP_SERVER_LINK,
        }
    },
}

export const config = {
    headers: {
        'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/x-www-form-urlencoded'
    },
}
