import { toast } from "react-toastify";

export const notifySuccess = (message) => {

    toast.success(message, {
        draggable: false,
        position: toast.POSITION.BOTTOM_RIGHT,        
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: false,
        theme: "dark",
    })
}

export const notifyError = (message) => {

    toast.error(message, {
        draggable: false,
        position: toast.POSITION.BOTTOM_RIGHT,        
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: false,
        theme: "dark",
    })
}