import React from 'react'
import './ScenePanel.css';
import { constant } from '../../utils/constant';
import { hoverTag, showcaseLoader } from '../../server/script'

class ScenePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    capturePose() {
        console.log("Capture pose function is called")
        document.getElementById("capture-screen-window").style.display = "flex";
    }


    render() {
        const url = constant.data.matterport.scene_url;
        return (
            <div className="Inner__Panel__Left">
                <button
                    id="add-mattertag-button"
                    className="add-mattertag-button"
                    onClick={hoverTag}>
                    <p id="plus-icon" className="plus-icon">+</p>
                </button>
                <button
                    id="capture-button"
                    className="capture-button"
                    onClick={() => this.capturePose()}>
                    <img alt="Camera Icon" id="cam-icon" className="cam-icon" src="/static/cam-b.png" />
                </button>
                <iframe
                    id="showcase"
                    title="Matterport 3D Scene"
                    onLoad={showcaseLoader}
                    src={url}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="xr-spatial-tracking"
                    allowFullScreen></iframe>
            </div>
        )
    }
}

export default ScenePanel;