import React from 'react'
import axios from 'axios';
import './Card.css';
import DropDown from '../DropDown/DropDown';
import EvidenceEditWindow from '../WindowComponent/EvidenceEditWindow';
import { notifySuccess, notifyError } from '../../utils/toast.js';
import { config, constant } from '../../utils/constant';
import { navigateToScreenshot } from '../../server/script'

class CardScreenshot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            evidenceMenuShow: false,
            editScreenshotWindow: false,
            screenshotID: 'undefined',
            label: 'undefined',
            description: 'undefined',
        };
    }

    openEvidenceMenu() {
        (this.setState({ evidenceMenuShow: true }));
    }

    closeEvidenceMenu() {
        (this.setState({ evidenceMenuShow: false }));
    }

    openEditWindow() {
        (this.setState({ editScreenshotWindow: true }));
    }

    closeEditWindow() {
        (this.setState({ editScreenshotWindow: false }));
    }

    async saveScreenshotDetailChanges(tempLabel, tempDesc) {

        this.closeEditWindow();

        //Update state
        this.setState({
            label: tempLabel,
            description: tempDesc,
        });

        //Save to database
        var id = this.props.screenshot.screenshotID;
        await axios.put(`${constant.data.server.dev_url}screenshot/${id}`, new URLSearchParams({
            title: tempLabel,
            description: tempDesc,
        }), config)
            .then(function (response) {
                console.log(response);
                notifySuccess("Screenshot detail has been succesfully updated!");
            })
            .catch(function (error) {
                console.log(error);
                notifyError("Failed to update screenshot detail!");
            });

        //Refresh state from database
        this.props.refreshAPIscreenshots();
    }

    async deleteScreenshot() {

        //Delete from database
        var id = this.state.screenshotID;

        await axios.delete(
            `${constant.data.server.dev_url}screenshot/${id}`,
        ).then(result => {
            console.log(result);
            notifySuccess("Screenshot has been succesfully deleted!");
        }).catch(err => {
            console.log(err);
            notifyError("Failed to delete screenshot!");
        })

        //Hide Menu
        this.closeEvidenceMenu();

        //Refresh state from database
        this.props.refreshAPIscreenshots();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!!prevState.screenshot && prevProps.screenshot.length !== prevState.screenshot.length) {
            console.log(prevProps.screenshot)
            this.setState(
                {
                    screenshotID: prevProps.screenshot.screenshotID,
                    label: prevProps.screenshot.title,
                    description: prevProps.screenshot.description,
                }
            )
        }
    }

    componentDidMount() {
        this.setState(
            {
                screenshotID: this.props.screenshot.screenshotID,
                label: this.props.screenshot.title,
                description: this.props.screenshot.description,
            }
        )
    }

    callback(value) {
        if (value === 'Edit') {
            this.openEditWindow();
            this.closeEvidenceMenu()
        }
        if (value === 'Delete') {
            this.deleteScreenshot()
        }
    }

    render() {
        this.state.screenshotID === 'undefined' &&
            (
                this.setState(
                    {
                        screenshotID: this.props.screenshot.screenshotID,
                        label: this.props.screenshot.title,
                        description: this.props.screenshot.description,
                    }
                )
            );
        return (
            <div className="Card__Container" id={`card-screenshot-${this.state.screenshotID}`} >
                {this.state.editScreenshotWindow && (
                    <EvidenceEditWindow
                        id={this.state.screenshotID}
                        type={this.props.type}
                        valueLabel={this.state.label}
                        valueDescription={this.state.description}
                        onCancel={() => this.closeEditWindow()}
                        onSave={(tempLabel, tempDesc) => this.saveScreenshotDetailChanges(tempLabel, tempDesc)}
                    />)}
                <button className="Card__Navigate__Button" onClick={() => navigateToScreenshot(this.state.screenshotID)}>
                    <div className="Card__Left">
                        <img className="Card__Image" src='/static/photo-camera-svgrepo-com.svg' alt="Screenshots" />
                    </div>
                    <div className="Card__Right">
                        <strong><p className="Card__Title">{this.state.label}</p></strong>
                        <div className="Card__Description"><p className="Card__Description__p">{this.state.description}</p></div>
                    </div>
                </button>
                <div className="Card__Menu">
                    <DropDown options={['Edit', 'Delete']} callback={(e) => this.callback(e)} />
                </div>
            </div>
        )
    }
}

export default CardScreenshot;