import React from 'react'
import axios from 'axios';
import './Card.css';
import DropDown from '../DropDown/DropDown';
import EvidenceEditWindow from '../WindowComponent/EvidenceEditWindow';
import { notifySuccess, notifyError } from '../../utils/toast.js';
import { config, constant } from '../../utils/constant';


class CardImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            evidenceMenuShow: false,
            editImageWindow: false,
            imageID: 'undefined',
            label: 'undefined',
            description: 'undefined',
        };
    }

    openEvidenceMenu() {
        (this.setState({ evidenceMenuShow: true }));
    }

    closeEvidenceMenu() {
        (this.setState({ evidenceMenuShow: false }));
    }

    openEditWindow() {
        (this.setState({ editImageWindow: true }));
    }

    closeEditWindow() {
        (this.setState({ editImageWindow: false }));
    }

    async saveImageDetailChanges(tempLabel, tempDesc) {

        this.closeEditWindow();

        //Update state
        this.setState({
            label: tempLabel,
            description: tempDesc,
        });

        //Save to database
        var id = this.props.image.imageID;
        await axios.put(`${constant.data.server.dev_url}image/${id}`, new URLSearchParams({
            label: tempLabel,
            description: tempDesc,
        }), config)
            .then(function (response) {
                console.log(response);
                notifySuccess("Image detail has been succesfully updated!");
            })
            .catch(function (error) {
                console.log(error);
                notifyError("Failed to update image detail!");
            });

        //Refresh state from database
        this.props.refreshAPIimages();
    }

    async deleteImage() {

        //Delete from database
        var id = this.state.imageID;
        console.log(id);

        // delete file in server
        axios.delete(`${constant.data.server.dev_url}image/delete/${id}`)

        await axios.delete(
            `${constant.data.server.dev_url}image/${id}`,
        ).then(result => {
            console.log(result);
            notifySuccess("Image has been succesfully deleted!");
        }).catch(err => {
            console.log(err);
            notifyError("Failed to delete image!");
        })

        

        //Hide Menu
        this.closeEvidenceMenu();

        //Refresh state from database
        this.props.refreshAPIimages();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!!prevState.image && prevProps.image.length !== prevState.image.length) {
            console.log(prevProps.image)
            this.setState(
                {
                    imageID: prevProps.image.imageID,
                    label: prevProps.image.label,
                    description: prevProps.image.description,
                }
            )
        }
    }

    componentDidMount() {
        this.setState(
            {
                imageID: this.props.image.imageID,
                label: this.props.image.label,
                description: this.props.image.description,
            }
        )
    }

    callback(value) {
        if (value === 'Edit') {
            this.openEditWindow();
            this.closeEvidenceMenu()
        }
        if (value === 'Delete') {
            this.deleteImage()
        }
    }

    render() {
        this.state.imageID === 'undefined' &&
            (
                this.setState(
                    {
                        imageID: this.props.image.imageID,
                        label: this.props.image.label,
                        description: this.props.image.description,
                    }
                )
            );
        return (
            <div className="Card__Container" id={`card-image-${this.state.imageID}`} >
                {this.state.editImageWindow && (
                    <EvidenceEditWindow
                        id={this.state.imageID}
                        type={this.props.type}
                        valueLabel={this.state.label}
                        valueDescription={this.state.description}
                        onCancel={() => this.closeEditWindow()}
                        onSave={(tempLabel, tempDesc) => this.saveImageDetailChanges(tempLabel, tempDesc)}
                    />)}
                <button className="Card__Navigate__Button">
                    <div className="Card__Left">
                        <img className="Card__Image" src={'/static/uploads/' + this.state.imageID + '.png'} alt="Images" />
                    </div>
                    <div className="Card__Right">
                        <strong><p className="Card__Title">{this.state.label}</p></strong>
                        <div className="Card__Description"><p className="Card__Description__p">{this.state.description}</p></div>
                    </div>
                </button>
                <div className="Card__Menu">
                    <DropDown options={['Edit', 'Delete']} callback={(e) => this.callback(e)} />
                </div>
            </div>
        )
    }
}

export default CardImage;