//rafce --> React arrow function export component
import React from 'react'
import './FloorplanPanel.css';
import { constant } from '../../utils/constant';
import { showCompass, setCompassOrientation, setCompassPosition, setGridOnLoad } from '../../server/script';

class FloorplanPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const url = constant.data.matterport.floorplan_url;
        const settings = this.props.settings;
        if (settings.length !== 0) {
            showCompass(settings.isCompass);
            setCompassOrientation(settings.compassOrientation);
            setCompassPosition(settings.compassPos);
            setGridOnLoad(settings.isGrid, settings.gridScale);
        }
        return (
            <>
                <div className="Floorplan__Panel" id="Floorplan__Panel">
                    <img id="image" src={url}
                        className="Floorplan__Panel__Image"
                        alt="floorplan" />
                    <canvas id="grid" className="Floorplan__Panel__Image"></canvas>
                </div>
                {/* <div class ="parent">
                    <img id="image" src="/static/Floorplan.jpg" alt="floorplan" width="600" height="600" class="image"/>
                    <canvas id="grid" className="Floorplan__Panel__Image canvas"></canvas>
                </div> */}
                <div className="Compass__Panel" id="Compass__Icon__Container">
                    <img id="Compass__Image" src="/static/north-alt-svgrepo-com.svg" alt="compass" className="Compass__Logo" />
                </div>
            </>
        )
    }
}

export default FloorplanPanel;