import { constant, studentID, config } from '../utils/constant'
import { notifySuccess, notifyError } from '../utils/toast'


var showcaseIframe, showcase, tagID
let sweepID, camRotationX, camRotationY
let newTag
let lineValue;

let tagIDRoute = new Map();


// workaround to check when tag is available on the scene
let tagEditing = false;
let studentTags= [];

const axios = require('axios')

let isClicked = false;

let CONFIG = {
 'mpAPI' : '73mqt6wp8959tr0a78tnztrha'
}

export function showcaseLoader() {

    showcaseIframe = document.getElementById('showcase')

    console.warn('showcaseLoader src=' + showcaseIframe.src )
    
    try {
        window.MP_SDK.connect( showcaseIframe, CONFIG.mpAPI, '' )
        .then( onShowcaseConnect )
        .catch( handleError )
    }
    catch (e) { console.error(e) }
}


export function deleteTagOnScene(id) {

    removeTag(tagIDRoute.get(id));
    tagIDRoute.delete(id);

}


export function uploadPose(title, description) {

    axios.post(`${constant.data.server.dev_url}screenshot`, new URLSearchParams({
        title: title,
        description: description,
        student: studentID,
        rotationX: camRotationX,
        rotationY: camRotationY,
        sweepID: sweepID
    }), config).then(function (response) {
        notifySuccess("Screenshot has been succesfully created!");
    }).catch(function (error) {
        console.log(error);
        notifyError("Failed to create screenshot!");
    });
}



function handleError(e) {
    console.warn('handleError',e)
}

function onShowcaseConnect( response ) {

    showcase = response

    showcase.Tag.data.subscribe({
        onAdded: function (index, item, collection) {
        console.log('Mattertag added', index, item, collection)
        loadStudentTags(tagEditing)

        },
        onRemoved: function (index, item, collection) {
        console.log('Mattertag removed', index, item, collection)
        },
        // onUpdated: function (index, item, collection) {
        // console.log('Mattertag updated', index, item, collection)
        // }
    })

    showcase.Camera.pose.subscribe(function (pose) {
        // Changes to the Camera pose have occurred.
        camRotationX = pose.rotation.x;
        camRotationY = pose.rotation.y;
        sweepID = pose.sweep;

      });

}

function loadStudentTags(x) {

    if(!x) {
        tagEditing = true;

        axios.get(
            `${constant.data.server.dev_url}tags/search/${studentID}`).then(result => {

            studentTags = result.data;

            for(let i = 0; i < studentTags.length; i++) {

                createStudentTag(studentTags[i])

            }

        }).catch(err => {
            console.log(err);
        })


    }

}

function removeTag(id) {

    showcase.Mattertag.remove( id ).then(function(mattertagID) {

    })

}

export function navigateTag(id) {

    let sid = tagIDtoString(id);
    showcase.Mattertag.navigateToTag(sid, showcase.Mattertag.Transition.FLY)


}

export function isSceneLoaded() {

    return tagEditing

}

export function editTag(label, description, id) {

    let sid = tagIDtoString(id);
    console.log(sid);
    
    showcase.Mattertag.editBillboard(sid, {
        
        label: label,
        description: description

    })
}


export function navigateToScreenshot(id) {

    axios.get(`${constant.data.server.dev_url}screenshot/searchbyssid/${id}`).then(
        result => {
            
            let ss = result.data[0]
            console.log(ss);
            showcase.Sweep.moveTo(ss.sweepID, {
                rotation: {x: ss.rotationX, y: ss.rotationY},
                transition: showcase.Sweep.Transition.FLY,
                transitionTime: 2000,

            }).then(function(sweepId) {
                // Move successful.
                // console.log('Arrived at sweep ' + sweepId);
            }).catch(function(error) {
                console.log(error)
            });

        }

    )


}

export function removeEmptyTag() {

    console.log("TESTING")
    console.log(newTag.sid)
    removeTag(newTag.sid);

}

 
function createEmptyTag(xx, yy, zz) {

    let mattertagData = {

        label: defaultTag.label,
        description: defaultTag.description,
        anchorPosition: {x:xx, y:yy, z:zz},
        stemVector: {x:defaultTag.stemVectorX, y:defaultTag.stemVectorY, z:defaultTag.stemVectorZ},
        color: {r:defaultTag.colorr, g:defaultTag.colorg, b:defaultTag.colorb},
        // floorID: data.floorID
    }

    showcase.Mattertag.add( mattertagData ).then(function(mattertagID) {

        tagID = mattertagID;

    })

}



export function hoverTag() {

    createEmptyTag(0, 0, 0);

    let getData = false
    isClicked = false;
    setInterval(function(){
        var elem = document.activeElement;
        if(elem && elem.tagName === 'IFRAME') {
            isClicked = true;
            if(isClicked && !getData) {
                getNewData();
                document.getElementById("add-new-tag-window").style.display = "flex";
                getData = true;
            }
        }
    }, 100);

    showcase.Pointer.intersection.subscribe(function (intersectionData) {
        
        if(!isClicked) {
            showcase.Mattertag.editPosition(`${tagID}`, {

                anchorPosition: {
                    x: intersectionData.position.x,
                    y: intersectionData.position.y,
                    z: intersectionData.position.z
                },
                stemVector: {
                    x: 0.08,
                    y: 0.9,
                    z: -0.003
                }
            
            })
        }
    })
}

window.addEventListener('resize', function(event) {

    drawLinesOnChange()


})

function createStudentTag(data) {

    let mattertagData = {
        label: data.label,
        description: data.description,
        anchorPosition: {x:parseFloat(data.anchorPositionX), y:parseFloat(data.anchorPositionY), z:parseFloat(data.anchorPositionZ)},
        stemVector: {x:defaultTag.stemVectorX, y:defaultTag.stemVectorY, z:defaultTag.stemVectorZ},
        color: {r:defaultTag.colorr, g:defaultTag.colorg, b:defaultTag.colorb},
        // floorID: data.floorID
    }

    showcase.Mattertag.add( mattertagData ).then(function(mattertagID) {

        tagIDRoute.set(data.tagID, mattertagID);

    })


}

// get tag id and return mattertag id 
function tagIDtoString(id) {

    let sid = tagIDRoute.get(id)

    return JSON.stringify(sid).replace(/]|[[]/g, '').replace(/"/g, '');


}

export async function createNewPose(data) {

    await axios.post(`${constant.data.server.dev_url}screenshot`, new URLSearchParams({
        title: data.label,
        description: data.description,
        student: studentID,
        rotationX: camRotationX,
        rotationY: camRotationY,
        sweepID: sweepID
    }), config).then(function (response) {
        notifySuccess("Screenshot has been succesfully created!");
    }).catch(function (error) {
        console.log(error);
        notifyError("Failed to create screenshot!");
    });

}

export function createImage(fileName, data) {

    axios.post(`${constant.data.server.dev_url}image`, new URLSearchParams({

        imageID: fileName,
        label: data.label,
        description: data.description,
        student: studentID

    }), config).then(function (response) {
        notifySuccess("Image has been succesfully created!");
    }).catch(function (error) {
        console.log(error);
        notifyError("Failed to create image!");
    });
  
    
}

export async function uploadImageToServer(fileName) {

    let fileNamePNG = fileName  + '.png'
    let fileSource = document.getElementById("saveImg").src;

    urltoFile(fileSource, fileNamePNG).then(function(file){
  
        let fileData = new FormData();
        fileData.append('file', file)

        var fileConfig = {
            method: 'post',
            url: `${constant.data.server.dev_url}upload/`,
            headers: { "Content-Type": "multipart/form-data" },
            data : fileData
        };
        console.log(fileConfig)
        axios(fileConfig);
    
    })

}


function urltoFile(url, filename, mimeType){
    mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1];
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename, {type:mimeType});})
    );
}



export async function createNewTag(data) {

    let mattertagData = {
        label: data.label,
        description: data.description,
        anchorPosition: {x:newTag.anchorPosition.x, y:newTag.anchorPosition.y, z:newTag.anchorPosition.z},
        stemVector: {x:defaultTag.stemVectorX, y:defaultTag.stemVectorY, z:defaultTag.stemVectorZ},
        color: {r:defaultTag.colorr, g:defaultTag.colorg, b:defaultTag.colorb},
        // floorID: data.floorID
    }


    removeTag(newTag.sid);

    await showcase.Mattertag.add( mattertagData ).then(async function(mattertagID) {

        getNewData();
        await axios.post(`${constant.data.server.dev_url}tags`, new URLSearchParams({
            label: data.label,
            description: data.description,
            anchorPositionX: newTag.anchorPosition.x,
            anchorPositionY: newTag.anchorPosition.y,
            anchorPositionZ: newTag.anchorPosition.z,
            stemVectorX: newTag.stemVector.x,
            stemVectorY: newTag.stemVector.y,
            stemVectorZ: newTag.stemVector.z,
            colorr: 1,
            colorg: 0,
            colorb: 0,
            student: studentID,
            floorID: newTag.floorInfo.id,

            }), config)
            .then(function (response) {
                notifySuccess("Tag has been succesfully created!");
                return response;
            })
            .catch(function (error) {
                console.log(error);
                notifyError("Failed to create tag!");
            });

            try {
                const getResponse = await axios.get(`${constant.data.server.dev_url}tags/getnewid/${studentID}`);
                let idString = JSON.stringify(Object.values(getResponse.data)[0]); 
                let id = idString.match(/(\d+)/);
                tagIDRoute.set(parseInt(id[0]), mattertagID);
            }
            catch (e) {
                console.log("Error", e);
        }
    })
}


function getNewData() {
    showcase.Mattertag.getData().then(function(data) {
        newTag = data[data.length - 1]
    })
}


let defaultTag = {
    "label": "default tag",
    "description": "default tag",
    "anchorPositionX": 0,
    "anchorPositionY": 0,
    "anchorPositionZ": 0,
    "stemVectorX": 0.08,
    "stemVectorY": 0.9,
    "stemVectorZ": -0.003,
    "colorr": 1,
    "colorg": 0,
    "colorb": 0
}

function drawLinesOnChange() {

    var img = document.getElementById('image');
    var grid = document.getElementById('grid');
    grid.height = img.clientHeight;
    grid.width = img.clientWidth;
    var ctx = grid.getContext("2d");

    ctx.clearRect(0, 0, 900, 900);
    drawGrid(lineValue);


}

export function gridLines() {
    
    var img = document.getElementById('image');
    var grid = document.getElementById('grid');
    grid.height = img.clientHeight;
    grid.width = img.clientWidth;
    var ctx = grid.getContext("2d");

    if(document.getElementById("enableGrid").value === "0") {
        ctx.clearRect(0, 0, 900, 900);

    } else {
        ctx.clearRect(0, 0, 900, 900); //Refresh grid on click
        // if()
        let scaleValue = document.getElementById('scale').value;
        console.log("VALUE ", document.getElementById('scale').value);
        drawGrid(scaleValue);
    }
    
}

export function showCompass(status) {
    if (status === 1 || status === "1") {
        document.getElementById("Compass__Icon__Container").style.display = "flex";
    }
    if (status === 0 || status === "0") {
        document.getElementById("Compass__Icon__Container").style.display = "none";
    }
}

export function setCompassOrientation(degree) {
    document.getElementById("Compass__Image").style.transform = `rotate(${degree}deg)`;
}

export function setCompassPosition(pos) {
    switch (pos) {
        case 'top':
            document.getElementById("Compass__Icon__Container").style.alignItems = "flex-start";
            document.getElementById("Compass__Icon__Container").style.justifyContent = "center";
            break;
        case 'topleft':
            document.getElementById("Compass__Icon__Container").style.alignItems = "flex-start";
            document.getElementById("Compass__Icon__Container").style.justifyContent = "left";
            break;
        case 'topright':
            document.getElementById("Compass__Icon__Container").style.alignItems = "flex-start";
            document.getElementById("Compass__Icon__Container").style.justifyContent = "right";
            break;
        case 'left':
            document.getElementById("Compass__Icon__Container").style.alignItems = "unset";
            document.getElementById("Compass__Icon__Container").style.justifyContent = "left";
            break;
        case 'right':
            document.getElementById("Compass__Icon__Container").style.alignItems = "unset";
            document.getElementById("Compass__Icon__Container").style.justifyContent = "right";
            break;
        case 'bottom':
            document.getElementById("Compass__Icon__Container").style.alignItems = "flex-end";
            document.getElementById("Compass__Icon__Container").style.justifyContent = "center";
            break;
        case 'bottomleft':
            document.getElementById("Compass__Icon__Container").style.alignItems = "flex-end";
            document.getElementById("Compass__Icon__Container").style.justifyContent = "left";
            break;
        case 'bottomright':
            document.getElementById("Compass__Icon__Container").style.alignItems = "flex-end";
            document.getElementById("Compass__Icon__Container").style.justifyContent = "right";
            break;
        default:
            document.getElementById("Compass__Icon__Container").style.alignItems = "flex-start";
            document.getElementById("Compass__Icon__Container").style.justifyContent = "right";
            break;
    }
}

export function setGridOnLoad(isGrid, gridScale) {
    if (isGrid === 1) {
        var img = document.getElementById('image');
        var grid = document.getElementById('grid');
        grid.height = img.clientHeight;
        grid.width = img.clientWidth;
        drawGrid(gridScale);

    }
}

function drawGrid(scale) {
    
    lineValue = scale;
    scale = parseInt(scale)
    var grid = document.getElementById('grid');
    var ctx = grid.getContext("2d");
    let x = 2; //start at 2 as we start at the second cell
    let g = 0; //start at 0 for array with alphabet
    const alphabet = ["B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z",
    "AA","AB","AC","AD","AE","AF","AG","AH","AI","AJ","AK","AL","AM","AN","AO","AP","AQ","AR","AS","AT","AU","AV","AW","AX","AY","AZ"];

    var parent = document.getElementById('Floorplan__Panel');
    var size = parent.clientWidth;
    
    for(var i = 0; i <= size; i = i + scale) //Change the number in i=i+15 to increase or decrease amount of squares (distance) 1m = 15 605
    {
        // Create grid
        ctx.moveTo(i, 0);
        ctx.lineTo(i, size);

        ctx.moveTo(0, i);
        ctx.lineTo(size, i);
        
        ctx.strokeStyle="#f0f0f0"; //colour of lines drawn
        ctx.stroke(); //draw line

        ctx.fillStyle = "yellow"; //colour of coordinate

        let pos_x, pos_y;
        if (scale === 12) {
            ctx.font = "8px Arial"; //font of coordinate
            pos_x = 8;
            pos_y = 8;
        } else if (scale === 24) {
            ctx.font = "16px Arial"; //font of coordinate
            pos_x = 18;
            pos_y = 18;
        } else {
            ctx.font = "20px Arial"; //font of coordinate
            pos_x = 20;
            pos_y = 20;
        }
    
        if(i !== 0) {
            ctx.fillText((alphabet[g]) ,i+2, pos_x, 10); //Display coordinates on x-axis
            ctx.fillText((x), 2, i+pos_y, 10); //Display coordinates on y-axis
            g++; //Increment each loop for x-coords alphabet
            x++; //Increment each loop for y-coords numbers
        }
    }
}


export function getOrientation() {
    rotateOrientation(document.getElementById('orientation').value);
}

function rotateOrientation(option) {

    option = option.toString();
    const rotated = document.getElementById('grid');
    const rotates = document.getElementById('image');
    if(option === "0") {
        rotated.style.transform = 'rotate(0deg)';
        rotates.style.transform = 'rotate(0deg)';
      }
      if(option === "90") {
        rotated.style.transform = 'rotate(90deg)';
        rotates.style.transform = 'rotate(90deg)';
      }
      if(option === "180") {
        rotated.style.transform = 'rotate(180deg)';
        rotates.style.transform = 'rotate(180deg)';
      }
      if(option === "270") {
        rotated.style.transform = 'rotate(270deg)';
        rotates.style.transform = 'rotate(270deg)';
      }
  
}

export function addHoverListener() {
    document.getElementById("capture-button").addEventListener("mouseover", function(e) {
        
        console.log("test")
        document.getElementById("cam-icon").src = "/static/cam-w.png"
    
    })

    document.getElementById("capture-button").addEventListener("mouseleave", function(e) {

        document.getElementById("cam-icon").src = "/static/cam-b.png"
    
    })
}
