import React from 'react';
import axios from 'axios';
import './TabPanel.css';
import TabInstruction from '../TabContents/TabInstruction';
import TabEvidences from '../TabContents/TabEvidences';
import TabNotes from '../TabContents/TabNotes';
import TabSettings from '../TabContents/TabSettings';
import { ToastContainer, Zoom } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { notifySuccess, notifyError } from '../../utils/toast';
import { constant, studentID, config } from '../../utils/constant';
import { showCompass, setCompassOrientation, setCompassPosition } from '../../server/script';


class TabPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 'Instruction',
            studentNotes: 'undefined',
            settingID: 'undefined',
            floorID: 'undefined',
            layout: 'undefined',
            isCompass: 'undefined',
            compassPos: 'undefined',
            compassOrientation: 'undefined',
            isGrid: 'undefined',
            gridScale: 'undefined',
            onWindowLoad: false
        };
    }

    //Tab Notes -- related
    notesOnChange(e) {
        this.setState({ studentNotes: e.target.value });
    }


    notesOnSave() {
        //Save notes to database
        axios.put(`${constant.data.server.dev_url}notes/${studentID}`,
            `text=${this.state.studentNotes}`
            , config)
            .then(function (response) {
                console.log(response);
                notifySuccess("Notes has been succesfully updated!");
            })
            .catch(function (error) {
                console.log(error);
                notifyError("Failed to update notes!");
            });
    }

    onExport() {
        const element = document.createElement('a');
        const file = new Blob([JSON.stringify(this.props, null, 2)], {
            type: "text/plain;charset=utf-8"
        });
        element.href = URL.createObjectURL(file);
        element.download = "ExportedData.txt";
        document.body.appendChild(element);
        element.click();
    }

    //Tab Settings -- Related
    floorIDChange(e) {
        this.setState({ floorID: e.target.value });
    }
    layoutChange(e) {
        this.setState({ layout: e.target.value });
    }
    isCompassChange(e) {
        this.setState({ isCompass: e.target.value });
    }
    compassPosChange(e) {
        this.setState({ compassPos: e.target.value });
    }
    compassOrientationChange(e) {
        this.setState({ compassOrientation: e.target.value });
    }
    isGridChange(e) {
        this.setState({ isGrid: e.target.value });
    }
    gridScaleChange(e) {
        this.setState({ gridScale: e.target.value });
    }

    settingsOnSave() {

        axios.put(`${constant.data.server.dev_url}setting/${studentID}`, new URLSearchParams({
            floorID: 1,
            layout: this.state.layout,
            isCompass: this.state.isCompass,
            compassPos: this.state.compassPos,
            compassOrientation: this.state.compassOrientation,
            isGrid: this.state.isGrid,
            gridScale: this.state.gridScale,
        }), config)
            .then(function (response) {
                console.log(response);
                notifySuccess("Settings has been succesfully updated!");
            })
            .catch(function (error) {
                console.log(error);
                notifyError("Failed to update settings!");
            });

    }


    //Tab Change - Related
    changeTabContent(tabName) {
        document.getElementById("Instruction").style.backgroundColor = "black";
        document.getElementById("Notes").style.backgroundColor = "black";
        document.getElementById("Evidences").style.backgroundColor = "black";
        document.getElementById("Settings").style.backgroundColor = "black";
        document.getElementById(tabName).style.backgroundColor = "darkslategrey";
        this.setState(
            { currentTab: tabName }
        )
    }

    renderCurrentTab() {
        switch (this.state.currentTab) {
            case 'Instruction':
                return (
                    <TabInstruction />
                );
            case 'Notes':
                this.state.studentNotes === 'undefined' &&
                    (this.setState({ studentNotes: this.props.notes.text }));
                return (
                    <TabNotes
                        studentNotes={this.state.studentNotes}
                        onChange={(e) => { this.notesOnChange(e) }}
                        onSave={() => { this.notesOnSave() }}
                    />
                );
            case 'Evidences':
                return (
                    <TabEvidences
                        tags={this.props.tags}
                        screenshots={this.props.screenshots}
                        images={this.props.images}
                        refreshAPItags={this.props.refreshAPItags}
                        refreshAPIscreenshots={this.props.refreshAPIscreenshots}
                        refreshAPIimages={this.props.refreshAPIimages}
                    />
                );
            case 'Settings':
                if (this.state.isCompass === 'undefined') {
                    this.setState
                        ({
                            settingID: this.props.settings.settingID,
                            floorID: this.props.settings.floorID,
                            layout: this.props.settings.layout,
                            isCompass: this.props.settings.isCompass,
                            compassPos: this.props.settings.compassPos,
                            compassOrientation: this.props.settings.compassOrientation,
                            isGrid: this.props.settings.isGrid,
                            gridScale: this.props.settings.gridScale,
                        });
                }
                return (
                    <TabSettings
                        floorID={this.state.floorID}
                        layout={this.state.layout}
                        isCompass={this.state.isCompass}
                        compassPos={this.state.compassPos}
                        compassOrientation={this.state.compassOrientation}
                        isGrid={this.state.isGrid}
                        gridScale={this.state.gridScale}
                        floorIDChange={(e) => { this.floorIDChange(e) }}
                        layoutChange={(e) => { this.layoutChange(e) }}
                        isCompassChange={(e) => { this.isCompassChange(e) }}
                        compassPosChange={(e) => { this.compassPosChange(e) }}
                        compassOrientationChange={(e) => { this.compassOrientationChange(e) }}
                        isGridChange={(e) => { this.isGridChange(e) }}
                        gridScaleChange={(e) => { this.gridScaleChange(e) }}
                        settingsOnSave={(e) => { this.settingsOnSave(e) }}
                        showCompass={(e) => { showCompass(e) }}
                        setCompassPosition={(e) => { setCompassPosition(e) }}
                        setCompassOrientation={(e) => { setCompassOrientation(e) }}
                        onExport={() => { this.onExport() }}
                    />
                );
            default:
                return (
                    <div>Error retrieve page</div>
                );
        }
    }

    render() {
        return (
            <>
                <div className="Tab__Panel">
                    <ToastContainer
                        draggable={false}
                        transition={Zoom}
                        autoClose={8000}
                    />
                    <button id="Instruction" className="button" onClick={() => this.changeTabContent('Instruction')}>Instructions</button>
                    <button id="Notes" className="button" onClick={() => this.changeTabContent('Notes')}>Notes</button>
                    <button id="Evidences" className="button" onClick={() => this.changeTabContent('Evidences')}>Evidence</button>
                    <button id="Settings" className="button" onClick={() => this.changeTabContent('Settings')}>Settings</button>
                </div>
                <div className="tab-content-wrapper">{this.renderCurrentTab()}</div>
            </>
        )
    }
}

export default TabPanel;