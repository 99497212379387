import React from 'react';
import { gridLines } from '../../server/script';
import './TabContents.css';

class TabSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="tab-unit-wrapper">
                <div className="tab-settings-wrapper">
                    <strong><p className="settings-title">Settings</p></strong>
                    <div className="tab-settings-body">
                        <div className="tab-settings-left">
                            {/*<div className="setting-container"><label htmlFor="floor">Choose floor level</label></div>*/}
                            {/* <div className="setting-container"><label htmlFor="orientation">Choose layout orientation</label></div> */}
                            <div className="setting-container"><label htmlFor="grid">Grid</label></div>
                            <div className="setting-container"><label htmlFor="scale">Grid scale</label></div>
                            <div className="setting-container"><label htmlFor="compass">Compass</label></div>
                            <div className="setting-container"><label htmlFor="compass-position">Compass position</label></div>
                            <div className="setting-container"><label htmlFor="compass-orientation">Compass orientation</label></div>
                            <br />
                            <div className="setting-container">
                                <button className="settings-button" onClick={this.props.onExport} >Export JSON Data</button>
                            </div>
                        </div>
                        <div className="tab-settings-right">
                            {/*
                            <div className="setting-container">
                                <select value={this.props.floorID} onChange={(e) => this.props.floorIDChange(e)}>
                                    <option value="1">Floor 1</option>
                                    <option value="2">Floor 2</option>
                                    <option value="3">Floor 3</option>
                                </select>
                            </div>
                            */}
                            {/* <div className="setting-container">
                                <select id="orientation" name="orientation" value={this.props.layout} onChange={(e) => { this.props.layoutChange(e); getOrientation() }}>
                                    <option value="0">0</option>
                                    <option value="90">90</option>
                                    <option value="180">180</option>
                                    <option value="270">270</option>
                                </select>
                            </div> */}
                            <div className="setting-container">
                                <select id="enableGrid"
                                    value={this.props.isGrid}
                                    onChange={(e) => {
                                        this.props.isGridChange(e);
                                        gridLines()
                                    }}>
                                    <option value="1">On</option>
                                    <option value="0">Off</option>
                                </select>
                            </div>
                            <div className="setting-container">
                                <select id="scale" name="scale"
                                    value={this.props.gridScale}
                                    onChange={(e) => {
                                        this.props.gridScaleChange(e);
                                        gridLines()
                                    }}>
                                    <option value="24">2 meter</option>
                                    <option value="60">5 meter</option>
                                    <option value="120">10 meter</option>
                                </select>
                            </div>
                            <div className="setting-container">
                                <select id="isCompass"
                                    value={this.props.isCompass}
                                    onChange={(e) => {
                                        this.props.isCompassChange(e);
                                        this.props.showCompass(document.getElementById("isCompass").value)
                                    }}>
                                    <option value="1">On</option>
                                    <option value="0">Off</option>
                                </select>
                            </div>
                            <div className="setting-container">
                                <select id="compassPosition"
                                    value={this.props.compassPos}
                                    onChange={(e) => {
                                        this.props.compassPosChange(e);
                                        this.props.setCompassPosition(document.getElementById("compassPosition").value)
                                    }}>
                                    <option value="top">Top</option>
                                    <option value="bottom">Bottom</option>
                                    <option value="left">Left</option>
                                    <option value="right">Right</option>
                                    <option value="topleft">Top Left</option>
                                    <option value="topright">Top Right</option>
                                    <option value="bottomleft">Bottom Left</option>
                                    <option value="bottomright">Bottom Right</option>
                                </select>
                            </div>
                            <div className="setting-container">
                                <input id="compassOrientation"
                                    type="text"
                                    value={this.props.compassOrientation}
                                    onChange={(e) => {
                                        this.props.compassOrientationChange(e);
                                        this.props.setCompassOrientation(document.getElementById("compassOrientation").value)
                                    }}></input>
                            </div>
                            <br />
                            <div className="setting-container">
                                <button className="settings-button" onClick={this.props.settingsOnSave}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TabSettings;