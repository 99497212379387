//rafce --> React arrow function export component
import React from 'react'
import './TabContents.css';
import CardTag from '../Card/CardTag';
import CardScreenshot from '../Card/CardScreenshot';
import CardImage from '../Card/CardImage';
import EvidenceEditWindow from '../WindowComponent/EvidenceEditWindow';
import { createNewTag, createNewPose, createImage, uploadImageToServer, removeEmptyTag, addHoverListener } from '../../server/script'
import { v4 as uuidv4 } from 'uuid'

class TabEvidences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addNewTagWindow: false,
            captureScreenWindow: false,
            uploadImageWindow: false,
        };
    }

    openUploadImageWindow() {
        (this.setState({ uploadImageWindow: true }));
    }

    closeUploadImageWindow() {
        (this.setState({ uploadImageWindow: false }));
        document.getElementById("upload-image-window").style.display = "none";
    }


    openCaptureScreenWindow() {
        (this.setState({ captureScreenWindow: true }));
        document.getElementById("capture-button").style.display = "flex";
        (this.setState({ addNewTagWindow: false }));
        document.getElementById("add-mattertag-button").style.display = "none";
        addHoverListener();
    }

    closeCaptureScreenWindow() {
        (this.setState({ captureScreenWindow: false }));
        document.getElementById("capture-screen-window").style.display = "none";
        document.getElementById("capture-button").style.display = "none";
    }

    openNewTagWindow() {
        (this.setState({ addNewTagWindow: true }));
        document.getElementById("add-mattertag-button").style.display = "flex";
        (this.setState({ captureScreenWindow: false }));
        document.getElementById("capture-button").style.display = "none";
    }


    closeNewTagWindow() {
        (this.setState({ addNewTagWindow: false }));
        document.getElementById("add-new-tag-window").style.display = "none";
        document.getElementById("add-mattertag-button").style.display = "none";
    }

    createAPIImages(label, description) {

        let fileName = uuidv4();

        uploadImageToServer(fileName);
        createImage(fileName, { label, description });
        
        
    }

    render() {
        return (
            <div className="tab-unit-wrapper">
                {this.state.uploadImageWindow && (
                    <EvidenceEditWindow
                        class="upload-image-window"
                        id="upload-image-window"
                        type="upload"
                        valueLabel="Label"
                        valueDescription="Description"
                        onCancel={() => this.closeUploadImageWindow()}

                        onSave={async (label, description) => {
                            this.createAPIImages(label, description);
                            this.closeUploadImageWindow();
                            this.props.refreshAPIimages();
                        }}
                    />)}
                {this.state.captureScreenWindow && (
                    <EvidenceEditWindow
                        id="capture-screen-window"
                        type="capture"
                        valueLabel="Label"
                        valueDescription="Description"
                        onCancel={() => this.closeCaptureScreenWindow()}
                        onSave={async (label, description) => {
                            this.closeCaptureScreenWindow();
                            await createNewPose({ label, description });
                            this.props.refreshAPIscreenshots();
                        }}
                    />)}
                {this.state.addNewTagWindow && (
                    <EvidenceEditWindow
                        class="add-new-tag-window"
                        id="add-new-tag-window"
                        type={this.props.type}
                        valueLabel="Label"
                        valueDescription="Description"
                        onCancel={() => {this.closeNewTagWindow(); removeEmptyTag() }}

                        onSave={async (label, description) => {
                            this.closeNewTagWindow();
                            await createNewTag({ label, description });
                            this.props.refreshAPItags();
                        }}
                    />)}
                <div className="evidence-buttons-container">
                    <button onClick={() => this.openNewTagWindow()} className="button-evidence">
                        <img src="/static/pin-svgrepo-com.svg" alt="camera-icon" className="button-icon"></img>
                        <strong>Add Mattertag</strong>
                    </button>
                    <button onClick={() => this.openCaptureScreenWindow()} className="button-evidence">
                        <img src="/static/photo-camera-svgrepo-com.svg" alt="camera-icon" className="button-icon"></img>
                        <strong>Capture Pose</strong>
                    </button>
                    <button onClick={() => this.openUploadImageWindow()} className="button-evidence">
                        <img src="/static/picture-image-svgrepo-com.svg" alt="upload-icon" className="button-icon"></img>
                        <strong>Upload Image</strong>
                    </button>
                </div>
                <div className="evidence-cards-container">
                    {this.props.tags.map((tags) => (
                        <CardTag
                            tags={tags}
                            type="tags"
                            key={`tag-${tags.tagID}`}
                            refreshAPItags={this.props.refreshAPItags}
                        />
                    ))}
                    {this.props.screenshots.map((screenshots) => (
                        <CardScreenshot
                            screenshot={screenshots}
                            type="screenshot"
                            key={`screenshot-${screenshots.screenshotID}`}
                            refreshAPIscreenshots={this.props.refreshAPIscreenshots}
                        />
                    ))}
                    {this.props.images.map((images) => (
                        <CardImage
                            image={images}
                            type="image"
                            key={`image-${images.imageID}`}
                            refreshAPIimages={this.props.refreshAPIimages}
                        />
                    ))}
                </div>
            </div>
        )
    }
}

export default TabEvidences;