import React from 'react';
import { constant } from '../../utils/constant';
import './TabContents.css';

class TabNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="tab-unit-wrapper">
                <div className="Scene__Notes__Container">
                    <p><strong>Scene Notes</strong> </p>
                    <p>{constant.data.sceneNotes.text}</p>
                </div>
                <div className="Student__Notes__Container">
                    <p><strong>Student Notes</strong></p>
                    <textarea
                        maxLength="2000"
                        value={this.props.studentNotes}
                        onChange={this.props.onChange}
                    />
                </div>
                <div className="Notes__Button__Container">
                    <button onClick={this.props.onSave} className="student-notes-button">Save</button>
                </div>
            </div>
        )
    }
}

export default TabNotes;