import React from 'react';
import './TabContents.css';
import { constant } from '../../utils/constant';

class TabInstruction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const generalInstruction = constant.data.generalInstruction;
        return (
            <div className="tab-unit-wrapper justify">
                <h3>General Instruction</h3>
                {
                    Object.keys(generalInstruction).map((keyName, i) => (
                        <p key={i}>{generalInstruction[keyName]}</p>
                    ))
                }
            </div>
        )
    }
}

export default TabInstruction;