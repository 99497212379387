import React from 'react';
import './MainPanel.css';
import ScenePanel from './../ScenePanel/ScenePanel.jsx'
import FloorplanPanel from '../FloorplanPanel/FloorplanPanel';
import TabPanel from '../TabPanel/TabPanel';

class MainPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addButtonPressed: false,
            buttonRotated: false,
        };
    }
    render() {
        return (
            <>
                <div className="Panel">
                    <div className="Panel__Left">
                        <ScenePanel></ScenePanel>
                    </div>
                    <div className="Panel__Right">
                        <TabPanel
                            tags={this.props.tags}
                            screenshots={this.props.screenshots}
                            images={this.props.images}
                            students={this.props.students}
                            notes={this.props.notes}
                            settings={this.props.settings}
                            refreshAPItags={this.props.refreshAPItags}
                            refreshAPIscreenshots={this.props.refreshAPIscreenshots}
                            refreshAPIimages={this.props.refreshAPIimages}
                        >
                        </TabPanel>
                        <FloorplanPanel
                            settings={this.props.settings}
                        ></FloorplanPanel>
                    </div>
                </div>
            </>
        )
    }
}

export default MainPanel;