import React from 'react'
import axios from 'axios';
import './Card.css';
import DropDown from '../DropDown/DropDown';
import EvidenceEditWindow from '../WindowComponent/EvidenceEditWindow';
import { notifySuccess, notifyError } from '../../utils/toast.js';
import { config, constant } from '../../utils/constant';
import { deleteTagOnScene, navigateTag, editTag } from '../../server/script'

class CardTag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            evidenceMenuShow: false,
            editTagWindow: false,
            tagID: 'undefined',
            label: 'undefined',
            description: 'undefined',
        };
    }

    openEvidenceMenu() {
        (this.setState({ evidenceMenuShow: true }));
    }

    closeEvidenceMenu() {
        (this.setState({ evidenceMenuShow: false }));
    }

    openEditWindow() {
        (this.setState({ editTagWindow: true }));
    }

    closeEditWindow() {
        (this.setState({ editTagWindow: false }));
    }

    async saveTagDetailChanges(tempLabel, tempDesc) {

        this.closeEditWindow();

        //Update state
        this.setState({
            label: tempLabel,
            description: tempDesc,
        });

        //Save to database
        var tagId = this.props.tags.tagID;

        await axios.put(`${constant.data.server.dev_url}tags/${tagId}`, new URLSearchParams({
            label: tempLabel,
            description: tempDesc,
        }), config)
            .then(function (response) {
                console.log(response);
                notifySuccess("Tag detail has been succesfully updated!");
            })
            .catch(function (error) {
                console.log(error);
                notifyError("Failed to update tag!");
            });

        //Refresh state from database
        this.props.refreshAPItags();
    }

    async deleteTag() {

        //Delete from database
        var id = this.state.tagID;
        await axios.delete(
            `${constant.data.server.dev_url}tags/${id}`,
        ).then(result => {
            console.log(result);
            notifySuccess("Tag has been succesfully deleted!");
        }).catch(err => {
            console.log(err);
            notifyError("Failed to delete tag!");
        })

        // delete tag on matterport scene
        deleteTagOnScene(id);

        //Hide Menu
        this.closeEvidenceMenu();

        //Refresh state from database
        this.props.refreshAPItags();
    }

    //built-in prevprops // prevstate
    componentDidUpdate(prevProps, prevState) {
        if (!!prevState.tags && prevProps.tags.length !== prevState.tags.length) {
            this.setState(
                {
                    tagID: prevProps.tags.tagID,
                    label: prevProps.tags.label,
                    description: prevProps.tags.description,
                }
            )
        }
    }

    componentDidMount() {
        this.setState(
            {
                tagID: this.props.tags.tagID,
                label: this.props.tags.label,
                description: this.props.tags.description,
            }
        )
    }

    callback(value) {
        if (value === 'Edit') {
            this.openEditWindow();
            this.closeEvidenceMenu()
        }
        if (value === 'Delete') {
            this.deleteTag()
        }
    }

    render() {
        return (
            <div className="Card__Container" id={`card-tag-${this.state.tagID}`} >
                {this.state.editTagWindow && (<EvidenceEditWindow
                    id={this.state.tagID}
                    type={this.props.type}
                    valueLabel={this.state.label}
                    valueDescription={this.state.description}
                    onCancel={() => this.closeEditWindow()}
                    onSave={(tempLabel, tempDesc) => { this.saveTagDetailChanges(tempLabel, tempDesc); editTag(tempLabel, tempDesc, this.state.tagID) }}
                />)}
                <button className="Card__Navigate__Button" onClick={() => navigateTag(this.state.tagID)}>
                    <div className="Card__Left">
                        <img className="Card__Pin" src="/static/pin-svgrepo-com.svg" alt="Mattertags" />
                    </div>
                    <div className="Card__Right">
                        <strong><p className="Card__Title" >{this.state.label}</p></strong>
                        <div className="Card__Description"><p className="Card__Description__p">{this.state.description}</p></div>
                    </div>
                </button>
                <div className="Card__Menu">
                    <DropDown options={['Edit', 'Delete']} callback={(e) => this.callback(e)} />
                </div>
            </div>
        )
    }
}

export default CardTag;