
import React from 'react';
import ImageUploading from 'react-images-uploading';
import "./ImageDragDrop.css";

const ImageUpload = (props) => {
    const [images, setImages] = React.useState([]);

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);
    };

    return (
        <ImageUploading
            value={images}
            onChange={onChange}
            dataURLKey="data_url"
        >
            {({
                imageList,
                onImageUpload,
                isDragging,
                dragProps,
            }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                    <button
                        style={isDragging ? { color: 'red' } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="Image-upload-container"
                        placeholder='click or drop here'>click or drop here..
                        {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                                <img id="saveImg" src={image['data_url']} alt="" className="Image-upload-img" />
                            </div>
                        ))}
                    </button>

                </div>
            )}
        </ImageUploading>
    );
}

export default ImageUpload; 