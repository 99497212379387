import React from 'react';
import './App.css';
import MainPanel from './components/MainPanel/MainPanel';
import { constant, studentID } from './utils/constant';


const axios = require('axios');

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      students: [],
      notes: [],
      settings: [],
      screenshots: [],
      images: [],
      data: "",
    };
  }

  refreshAPItags = async () => {
    const res = await axios.get(
      `${constant.data.server.dev_url}tags/search/${studentID}`,
    )
    this.setState({ tags: res.data });
  }

  refreshAPIscreenshots = async () => {
    const res = await axios.get(
      `${constant.data.server.dev_url}screenshot/search/${studentID}`,
    )
    this.setState({ screenshots: res.data });
  }

  refreshAPIimages = async () => {

    console.log("API IMAGE")

    const res = await axios.get(
      `${constant.data.server.dev_url}image/search/${studentID}`,
    )
    this.setState({ images: res.data });
  }

  getAPItags() {
    axios.get(
      `${constant.data.server.dev_url}tags/search/${studentID}`,
    ).then(result => {
      // console.log(result);
      this.setState({ tags: result.data });
    }).catch(err => {
      console.log(err);
    })
  }

  getAPIscreenshots() {
    axios.get(
      `${constant.data.server.dev_url}screenshot/search/${studentID}`,
    ).then(result => {
      // console.log(result);
      this.setState({ screenshots: result.data });
    }).catch(err => {
      console.log(err);
    })
  }

  getAPIimages() {
    axios.get(
      `${constant.data.server.dev_url}image/search/${studentID}`,
    ).then(result => {
      // console.log(result);
      this.setState({ images: result.data });
    }).catch(err => {
      console.log(err);
    })
  }


  getAPIstudents() {
    axios.get(
      `${constant.data.server.dev_url}students/search/${studentID}`,
    ).then(result => {
      // console.log(result);
      this.setState({ students: result.data });
    }).catch(err => {
      console.log(err);
    })
  }

  getAPInotes() {
    axios.get(
      `${constant.data.server.dev_url}notes/search/${studentID}`,
    ).then(result => {
      // console.log(result);
      this.setState({ notes: result.data[0] });
    }).catch(err => {
      console.log(err);
    })
  }

  getAPISettings() {
    axios.get(
      `${constant.data.server.dev_url}setting/search/${studentID}`,
    ).then(result => {
      // console.log(result);
      this.setState({ settings: result.data[0] });
    }).catch(err => {
      console.log(err);
    })
  }

  componentWillMount() {
    this.getAPInotes();
    this.getAPIstudents();
    this.getAPItags();
    this.getAPIscreenshots();
    this.getAPIimages();
    this.getAPISettings();
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "//static.matterport.com/showcase-sdk/latest.js";
    script.async = true;

    document.body.appendChild(script)

    console.log(process.env)
  }

  render() {
    return (
      <div className="App">
        <MainPanel
          data={constant.data}
          students={this.state.students}
          tags={this.state.tags}
          screenshots={this.state.screenshots}
          notes={this.state.notes}
          images={this.state.images}
          settings={this.state.settings}
          refreshAPItags={this.refreshAPItags}
          refreshAPIscreenshots={this.refreshAPIscreenshots}
          refreshAPIimages={this.refreshAPIimages}
        />
      </div>
    );
  };
}

export default App;